import * as am4core from '@amcharts/amcharts4/core'
import * as de from '@amcharts/amcharts4/lang/de_DE'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

export enum Colors {
  white = '#ffffff',
  black = '#000000',
  cerulean = '#007cc8',
  brownGrey = '#8e8e8e',
  purpleyPink = '#d9399d',
  greyBlue = '#bfdef1',
  telefonie = '#4ca3d8',
  rustyRed = '#ce1111',
  blueGrey = '#68b7db'
}

export enum Month {
  'Jan.',
  'Febr.',
  'März',
  'Apr.',
  'Mai',
  'Juni',
  'Juli',
  'Aug.',
  'Sept.',
  'Okt.',
  'Nov.',
  'Dez.',
}

export const tooltipStyleObject = {
  getFillFromObject: false,
  background: {
    cornerRadius: 0,
    strokeOpacity: 0,
    fillOpacity: 1,
    filters: [
      {
        dx: 0,
        dy: 0,
        blur: 5,
        color: 'rgba(0, 0, 0, 1)',
      },
    ],
  },
  label: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
}

export const legend = {
  useDefaultMarker: true,
  contentAlign: 'right',
  maxHeight: 26,
  markers: {
    width: 11,
    height: 5,
    children: {
      cornerRadiusTopLeft: 0,
      cornerRadiusTopRight: 0,
      cornerRadiusBottomRight: 0,
      cornerRadiusBottomLeft: 0,
    },
  },
}

export const legendHide = {
  ...legend,
  opacity: 0,
  itemContainers: {
    clickable: false,
    focusable: false,
    cursorOverStyle: am4core.MouseCursorStyle.default,
  },
}

export const XYcursor = {
  type: 'XYCursor',
  lineY: {
    disabled: true,
  },
}

export const getCurYear = (): number => new Date().getFullYear()
export const getPrevYear = (): number => new Date().getFullYear() - 1
export const getCurMonth = (): number => new Date().getMonth()

export function getQuarterLabel(month: number, year: number): string {
  const startMonth = (month+12-2)%12
  const startYear = month < 2 ? year-1 : year
  return `${Month[startMonth]} ${startYear} - ${Month[month]} ${year}`
}

let license: string
const root = window.document.getElementById('root')
if (root && root.hasAttribute('data-license')) {
  license = root.getAttribute('data-license') || ''
  am4core.options.licenses = [license]
}
am4core.options.defaultLocale = de.default
am4core.options.autoSetClassName = true
am4core.options.autoDispose = true
am4core.useTheme(am4themesAnimated)

export const replaceSeparator = (num: number): string => {
  return num.toFixed(2).toString()
    .replace(/\./g, ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}


// It will be needed for W8!
// export const mapChartInit = (container: string) => {
//   am4core.ready(() => {
//     const map = am4core.create(container, am4maps.MapChart)
//     map.geodata = am4geodataWorldLow
//     map.geodataNames = lang
//     map.projection = new am4maps.projections.Miller()
//     const polygonSeries = new am4maps.MapPolygonSeries()
//     polygonSeries.language = new am4core.Language()
//     polygonSeries.language.locale = de.default
//     polygonSeries.useGeodata = true
//     map.series.push(polygonSeries)
//     const polygonTemplate = polygonSeries.mapPolygons.template
//     polygonTemplate.language = new am4core.Language()
//     polygonTemplate.language.locale = lang
//     polygonTemplate.tooltipText = '{name}'
//     polygonTemplate.fill = am4core.color('#dddddd')
//     polygonSeries.data = [
//       {
//         id: 'US',
//         name: 'United States',
//         value: 100,
//         fill: am4core.color('#007cc8'),
//       },
//       {
//         id: 'UA',
//         name: 'Ukraine',
//         value: 80,
//         fill: am4core.color('#007cc8'),
//       },
//     ]
//     polygonTemplate.propertyFields.fill = 'fill'
//   })
// }
