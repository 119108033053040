import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import {createRoot} from 'react-dom/client'
import App from './App'
import './index.scss'

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)
root.render(<App />)

console.log(`Dashboard version: ${process.env.REACT_APP_VERSION || ''}`)
