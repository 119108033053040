import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import {AxiosResponse} from 'axios'
import {StateInterface} from '../../UserContext'
import {
  Colors,
  getCurYear,
  getPrevYear,
  legend,
  Month,
  replaceSeparator,
  tooltipStyleObject,
  XYcursor,
} from '../../am4charts'
import API from '../../API'
import {
  tooltipDefault,
  tooltipDefaultLastYear,
  tooltipDefaultQuarter,
  tooltipDefaultQuarterLastYear,
  tooltipVariable,
  tooltipVariableLastYear,
  tooltipVariableQuarterYear,
  tooltipCall,
  tooltipCallLastYear,
} from './W1Tooltips'

interface IEvent {
  target: any
  type: string
}

export interface IW1Resp {
  year: number
  month: number
  phoneNumbers: number
  totalBasicFee: number
  totalVariableExpensesData: number
  totalVariableExpensesCalls: number
  totalVariableExpensesOther: number
  totalOtherExpenses: number
  totalNationalCallExpenses: number
  totalInternationalCallExpenses: number
  totalRoamingCallExpenses: number
  date?: Date
  tooltip?: ITooltip
  fullExpenses?: number
  fullExpensesPerUser?: number
  totalVariable?: number
  totalCall?: number
  title?: string
  color?: string
  dataMonth?: number
  monthTitle?: string
  totalAmountNet: number
}

interface ITooltip {
  [propName: string]: number | string
}

interface IWidgetData {
  curYear: Array<IW1Resp>
  prevYear: Array<IW1Resp>
}

interface IServerResponse {
  data: Array<IW1Resp>
}

interface IGeneratedData {
  curYearData: Array<IW1Resp>
  prevYearData: Array<IW1Resp>
}

let WidgetData: IWidgetData = {
  curYear: [],
  prevYear: [],
}

const fetchWidgetData = async (context: StateInterface, checked: boolean): Promise<Array<IW1Resp>> => {
  const url = `/providers/${context.provider}/w1?previousYear=${checked}${context.filter}`
  // eslint-disable-next-line no-return-await
  return await API.get(url).then((resp: AxiosResponse<IServerResponse>) => resp.data.data)
}

const getWidgetData = async (
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
  changeContext: boolean,
) => {
  checkChangeContext(changeContext)
  if (!WidgetData.curYear.length) {
    try {
      const [curYear, prevYear]: Array<Array<IW1Resp>> = await Promise.all([
        fetchWidgetData(context, false),
        fetchWidgetData(context, true),
      ])
      WidgetData.curYear = curYear
      WidgetData.prevYear = prevYear
    } catch {
      if (!context.error) {
        setContext({error: true})
      }
    }
  }
  return WidgetData
}

const checkChangeContext = (changeContext: boolean) => {
  if (changeContext) {
    WidgetData = {
      curYear: [],
      prevYear: [],
    }
  }
}

export const W1ChartInit = async (
  container: string,
  tab: number,
  checked: boolean,
  range: number,
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
  changeContext: boolean,
): Promise<am4core.Sprite> => {
  await getWidgetData(context, setContext, changeContext)

  switch (tab) {
    case 0: {
      if (range === 0) {
        return checked ? defaultLastYearInit(container) : YearInit(container)
      } else if (range === 1) {
        return checked ? QuarterLastYearInit(container) : QuarterInit(container)
      } else {
        return checked ? defaultMonthLastYearInit(container) : MonthInit(container)
      }
    }
    case 1: {
      if (range === 0) {
        return checked ? variableLastYearInit(container) : variableYearInit(container)
      } else if (range === 1) {
        return checked ? QuarterLastYearInit(container) : variableQuarterInit(container)
      } else {
        return checked ? variableMonthLastYear(container) : variableMonthInit(container)
      }
    }
    case 2: {
      if (range === 0) {
        return checked ? callLastYearInit(container) : callYearInit(container)
      } else if (range === 1) {
        return checked ? callQuarterLastYearInit(container) : callQuarterInit(container)
      } else {
        return checked ? callMonthLastYearInit(container) : callMonthInit(container)
      }
    }
    default:
      return YearInit(container)
  }
}

const YearInit = (container: string): am4core.Sprite => {
  const {curYearData} = generateYearData()
  const series = [
    {
      type: 'LineSeries',
      dataFields: {
        dateX: 'date',
        valueY: 'totalOtherExpenses',
      },
      stroke: Colors.greyBlue,
      fill: Colors.greyBlue,
      name: 'Einmalzahlungen',
      tooltipHTML: tooltipDefault,
      tooltip: tooltipStyleObject,
      fillOpacity: 0.6,
      strokeWidth: 1,
      stacked: true,
    },
    {
      type: 'LineSeries',
      data: curYearData,
      legendSettings: {
        labelText: 'Grundgebühr',
      },
      dataFields: {
        dateX: 'date',
        valueY: 'totalBasicFee',
      },
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      name: 'Grundgebühr',
      tooltipHTML: tooltipDefault,
      tooltip: tooltipStyleObject,
      fillOpacity: 1,
      strokeWidth: 1,
      stacked: true,
    },
    {
      type: 'LineSeries',
      legendSettings: {
        labelText: 'Nutzungskosten',
      },
      dataFields: {
        dateX: 'date',
        valueY: 'totalVariable',
      },
      stroke: Colors.telefonie,
      fill: Colors.telefonie,
      name: 'Variable Kosten',
      tooltipHTML: tooltipDefault,
      tooltip: tooltipStyleObject,
      fillOpacity: 1,
      strokeWidth: 1,
      stacked: true,
    },
  ]

  return am4core.createFromConfig(
    {
      autoSetClassName: true,
      data: curYearData,
      series,
      legend,
      xAxes: [
        {
          type: 'DateAxis',
          dateFormats: {
            month: 'MMM',
          },
          dataFields: {
            category: 'date',
          },
          renderer: {
            minGridDistance: 50,
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.3,
            cellEndLocation: 0.8,
          },
          // first item to the right
          startLocation: 0.3,
          endLocation: 0.7,
          baseInterval: {
            timeUnit: 'month',
            count: 1,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          //Disable Y-tooltip
          cursorTooltipEnabled: false,
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      cursor: {
        ...XYcursor,
        maxTooltipDistance: -1,
      },
    },
    container,
    am4charts.XYChart,
  )
}

const defaultLastYearInit = (container: string): am4core.Sprite => {
  const {prevYearData, curYearData} = generateYearData()
  prevYearData.shift()
  curYearData.shift()
  const series = [
    {
      data: prevYearData,
      type: 'LineSeries',
      legendSettings: {
        labelText: 'Vorjahr',
      },
      dataFields: {
        dateX: 'date',
        valueY: 'fullExpenses',
      },
      stroke: Colors.purpleyPink,
      fill: Colors.purpleyPink,
      name: 'Vorjahr',
      tooltipHTML: tooltipDefaultLastYear,
      tooltipText: `[bold]{tooltip.monthLast} {tooltip.curYear}[/]
----
Gesamtkosten: {tooltip.totalVariable}
Rufnummern: {tooltip.numbersCount}
Kosten i. D. p. Rufnummer: {tooltip.fullExpensesPerUser}[/]
----
[bold]{tooltip.monthLast} {tooltip.prevYear}[/]
----
Gesamtkosten: {tooltip.totalVariableLast}
Rufnummern: {tooltip.numbersCountLast}
Kosten i. D. p. Rufnummer: {tooltip.fullExpensesPerUserLast}`,
      tooltip: tooltipStyleObject,
      strokeWidth: 1,
      stacked: true,
    },
    {
      data: curYearData,
      type: 'LineSeries',
      legendSettings: {
        labelText: 'Jahresübersicht',
      },
      dataFields: {
        dateX: 'date',
        valueY: 'fullExpenses',
      },
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      name: 'Jahresübersicht',
      tooltipHTML: tooltipDefaultLastYear,
      tooltipText: `[bold]{tooltip.monthLast} {tooltip.curYear}[/]
----
Gesamtkosten: {tooltip.totalVariable}
Rufnummern: {tooltip.numbersCount}
Kosten i. D. p. Rufnummer: {tooltip.fullExpensesPerUser}[/]
----
[bold]{tooltip.monthLast} {tooltip.prevYear}[/]
----
Gesamtkosten: {tooltip.totalVariableLast}
Rufnummern: {tooltip.numbersCountLast}
Kosten i. D. p. Rufnummer: {tooltip.fullExpensesPerUserLast}`,
      tooltip: tooltipStyleObject,
      strokeWidth: 1,
    },
  ]

  return am4core.createFromConfig(
    {
      autoSetClassName: true,
      series,
      legend,
      xAxes: [
        {
          type: 'DateAxis',
          dataFields: {
            category: 'date',
          },
          renderer: {
            minGridDistance: 50,
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.3,
            cellEndLocation: 0.8,
          },
          adapter: {},
          // first item to the right
          startLocation: 0.3,
          endLocation: 0.7,
          baseInterval: {
            timeUnit: 'month',
            count: 1,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          //Disable Y-tooltip
          cursorTooltipEnabled: false,
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      cursor: {
        ...XYcursor,
        maxTooltipDistance: -1,
      },
    },
    container,
    am4charts.XYChart,
  )
}

const QuarterInit = (container: string): am4core.Sprite => {
  const {curYearData} = generateQuarterData()
  const series = [
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'totalOtherExpenses',
        categoryX: 'monthTitle',
      },
      name: 'Einmalzahlungen',
      stroke: Colors.greyBlue,
      fill: Colors.greyBlue,
      columns: {
        tooltipHTML: tooltipDefaultQuarter,
      },
      tooltip: tooltipStyleObject,
      stacked: true,
    },
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'totalBasicFee',
        categoryX: 'monthTitle',
      },
      name: 'Grundgebühr',
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      columns: {
        tooltipHTML: tooltipDefaultQuarter,
      },
      tooltip: tooltipStyleObject,
      stacked: true,
    },
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'totalVariable',
        categoryX: 'monthTitle',
      },
      name: 'Variable Kosten',
      stroke: Colors.telefonie,
      fill: Colors.telefonie,
      columns: {
        tooltipHTML: tooltipDefaultQuarter,
      },
      tooltip: tooltipStyleObject,
      stacked: true,
    },
  ]

  return am4core.createFromConfig(
    {
      data: curYearData,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'monthTitle',
          },
          renderer: {
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.4,
            cellEndLocation: 0.6,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      series,
      legend,
    },
    container,
    am4charts.XYChart,
  )
}

const MonthInit = (container: string): am4core.Sprite => {
  const monthOverYears = generateMonthData()
  const data = [
    {
      title: 'Grundgebühr',
      color: '#007cc8',
      dataMonth: monthOverYears[1][0].totalBasicFee,
      tooltip: monthOverYears[1][0].tooltip,
      phoneNumbers: monthOverYears[1][0].phoneNumbers,
    },
    {
      title: 'Variable Kosten',
      color: '#4ca3d8',
      dataMonth: monthOverYears[1][0].totalVariable,
      tooltip: monthOverYears[1][0].tooltip,
      phoneNumbers: monthOverYears[1][0].phoneNumbers,
    },
    {
      title: 'Einmalzahlungen',
      color: '#bfdef1',
      dataMonth: monthOverYears[1][0].totalOtherExpenses,
      tooltip: monthOverYears[1][0].tooltip,
      phoneNumbers: monthOverYears[1][0].phoneNumbers,
    },
  ]
  const series = [
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'dataMonth',
        categoryX: 'title',
      },
      columns: {
        template: {
          tooltipHTML: tooltipDefaultQuarter,
          // Events for columns
          events: {
            inited: (event: IEvent) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              event.target.fill = event.target.dataItem._dataContext.color
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              event.target.stroke = event.target.dataItem._dataContext.color
            },
          },
        },
      },
      legendSettings: {
        labelText: `${data[0].phoneNumbers} Rufnummern`,
      },
      tooltip: tooltipStyleObject,
    },
  ]

  return am4core.createFromConfig(
    {
      data,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'title',
          },
          renderer: {
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
              location: 0.5,
            },
            cellStartLocation: 0.42,
            cellEndLocation: 0.58,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      series,
      legend: {
        type: 'Legend',
        contentAlign: 'right',
        markers: {
          width: 0,
          height: 0,
        },
      },
    },
    container,
    am4charts.XYChart,
  )
}

const defaultMonthLastYearInit = (container: string): am4core.Sprite => {
  const monthOverYears = generateMonthData()
  const data = [
    {
      title: 'Grundgebühr',
      fullExpenses: monthOverYears[1][0].totalBasicFee,
      fullExpensesLast: monthOverYears[0][0].totalBasicFee,
      tooltip: monthOverYears[0][0].tooltip,
    },
    {
      title: 'Variable Kosten',
      fullExpenses: monthOverYears[1][0].totalVariable,
      fullExpensesLast: monthOverYears[0][0].totalVariable,
      tooltip: monthOverYears[0][0].tooltip,
    },
    {
      title: 'Einmalzahlungen',
      fullExpenses: monthOverYears[1][0].totalOtherExpenses,
      fullExpensesLast: monthOverYears[0][0].totalOtherExpenses,
      tooltip: monthOverYears[0][0].tooltip,
    },
  ]
  const series = [
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'fullExpensesLast',
        categoryX: 'title',
      },
      name: `${monthOverYears[0][0].year}`,
      stroke: Colors.purpleyPink,
      fill: Colors.purpleyPink,
      columns: {
        tooltipHTML: tooltipVariableQuarterYear,
      },
      tooltip: tooltipStyleObject,
    },
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'fullExpenses',
        categoryX: 'title',
      },
      name: `${monthOverYears[1][0].year}`,
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      columns: {
        tooltipHTML: tooltipVariableQuarterYear,
      },
      tooltip: tooltipStyleObject,
    },
  ]

  return am4core.createFromConfig(
    {
      data,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'title',
          },
          renderer: {
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.4,
            cellEndLocation: 0.6,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      series,
      legend,
    },
    container,
    am4charts.XYChart,
  )
}

const variableYearInit = (container: string): am4core.Sprite => {
  const {curYearData} = generateYearData()
  const series = [
    {
      type: 'LineSeries',
      dataFields: {
        dateX: 'date',
        valueY: 'totalVariableExpensesOther',
      },
      stroke: Colors.greyBlue,
      fill: Colors.greyBlue,
      name: 'Sonstiges',
      tooltipHTML: tooltipVariable,
      tooltip: tooltipStyleObject,
      fillOpacity: 0.6,
      strokeWidth: 1,
      stacked: true,
    },
    {
      type: 'LineSeries',
      data: curYearData,
      legendSettings: {
        labelText: 'Daten',
      },
      dataFields: {
        dateX: 'date',
        valueY: 'totalVariableExpensesData',
      },
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      name: 'Daten',
      tooltipHTML: tooltipVariable,
      tooltip: tooltipStyleObject,
      fillOpacity: 1,
      strokeWidth: 1,
      stacked: true,
    },
    {
      type: 'LineSeries',
      legendSettings: {
        labelText: 'Sprache',
      },
      dataFields: {
        dateX: 'date',
        valueY: 'totalVariableExpensesCalls',
      },
      stroke: Colors.telefonie,
      fill: Colors.telefonie,
      name: 'Sprache',
      fillOpacity: 1,
      strokeWidth: 1,
      stacked: true,
    },
  ]

  return am4core.createFromConfig(
    {
      autoSetClassName: true,
      data: curYearData,
      series,
      legend,
      xAxes: [
        {
          type: 'DateAxis',
          dataFields: {
            category: 'date',
          },
          renderer: {
            minGridDistance: 50,
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.3,
            cellEndLocation: 0.8,
          },
          // first item to the right
          startLocation: 0.3,
          endLocation: 0.7,
          baseInterval: {
            timeUnit: 'month',
            count: 1,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          //Disable Y-tooltip
          cursorTooltipEnabled: false,
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      cursor: {
        ...XYcursor,
        maxTooltipDistance: -1,
      },
    },
    container,
    am4charts.XYChart,
  )
}

const variableLastYearInit = (container: string): am4core.Sprite => {
  const {prevYearData, curYearData} = generateYearData()
  prevYearData.shift()
  curYearData.shift()
  const series = [
    {
      data: prevYearData,
      type: 'LineSeries',
      legendSettings: {
        labelText: 'Vorjahr',
      },
      dataFields: {
        dateX: 'date',
        valueY: 'totalVariable',
      },
      stroke: Colors.purpleyPink,
      fill: Colors.purpleyPink,
      name: `${prevYearData[0].year}`,
      tooltipHTML: tooltipVariableLastYear,
      tooltip: tooltipStyleObject,
      strokeWidth: 1,
    },
    {
      data: curYearData,
      type: 'LineSeries',
      legendSettings: {
        labelText: 'Jahresübersicht',
      },
      dataFields: {
        dateX: 'date',
        valueY: 'totalVariable',
      },
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      name: `${prevYearData[0].year}`,
      tooltipHTML: tooltipVariable,
      tooltip: tooltipStyleObject,
      strokeWidth: 1,
    },
  ]

  return am4core.createFromConfig(
    {
      autoSetClassName: true,
      series,
      legend,
      xAxes: [
        {
          type: 'DateAxis',
          dataFields: {
            category: 'date',
          },
          renderer: {
            minGridDistance: 50,
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.3,
            cellEndLocation: 0.8,
          },
          // first item to the right
          startLocation: 0.3,
          endLocation: 0.7,
          baseInterval: {
            timeUnit: 'month',
            count: 1,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          //Disable Y-tooltip
          cursorTooltipEnabled: false,
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      cursor: {
        ...XYcursor,
        maxTooltipDistance: -1,
      },
    },
    container,
    am4charts.XYChart,
  )
}

const variableQuarterInit = (container: string): am4core.Sprite => {
  const {curYearData} = generateQuarterData()
  const series = [
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'totalVariableExpensesOther',
        categoryX: 'monthTitle',
      },
      name: 'Sonstiges',
      columns: {
        tooltipHTML: tooltipDefaultQuarter,
      },
      tooltip: tooltipStyleObject,
      stroke: Colors.greyBlue,
      fill: Colors.greyBlue,
      stacked: true,
    },
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'totalVariableExpensesData',
        categoryX: 'monthTitle',
      },
      name: 'Daten',
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      columns: {
        tooltipHTML: tooltipDefaultQuarter,
      },
      tooltip: tooltipStyleObject,
      stacked: true,
    },
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'totalVariableExpensesCalls',
        categoryX: 'monthTitle',
      },
      name: 'Sprache',
      columns: {
        tooltipHTML: tooltipDefaultQuarter,
      },
      tooltip: tooltipStyleObject,
      stroke: Colors.telefonie,
      fill: Colors.telefonie,
      stacked: true,
    },
  ]

  return am4core.createFromConfig(
    {
      data: curYearData,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'monthTitle',
          },
          renderer: {
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.4,
            cellEndLocation: 0.6,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      series,
      legend,
    },
    container,
    am4charts.XYChart,
  )
}

const QuarterLastYearInit = (container: string): am4core.Sprite => {
  const {prevYearData, curYearData} = generateQuarterData()
  const series = [
    {
      data: prevYearData,
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'fullExpenses',
        categoryX: 'monthTitle',
      },
      labelText: `${prevYearData[0].year}`,
      name: `${prevYearData[0].year}`,
      stroke: Colors.purpleyPink,
      fill: Colors.purpleyPink,
      columns: {
        tooltipHTML: tooltipDefaultQuarterLastYear,
      },
      tooltip: tooltipStyleObject,
    },
    {
      data: curYearData,
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'fullExpenses',
        categoryX: 'monthTitle',
      },
      labelText: `${curYearData[0].year}`,
      name: `${curYearData[0].year}`,
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      columns: {
        tooltipHTML: tooltipDefaultQuarterLastYear,
      },
      tooltip: tooltipStyleObject,
    },
  ]

  return am4core.createFromConfig(
    {
      data: curYearData,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'monthTitle',
          },
          renderer: {
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.4,
            cellEndLocation: 0.6,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      series,
      legend,
    },
    container,
    am4charts.XYChart,
  )
}

const variableMonthInit = (container: string): am4core.Sprite => {
  const monthOverYears = generateMonthData()
  const data = [
    {
      title: 'Daten',
      dataMonth: monthOverYears[1][0].totalVariableExpensesData,
      color: '#007cc8',
      tooltip: monthOverYears[1][0].tooltip,
      phoneNumbers: monthOverYears[1][0].phoneNumbers,
    },
    {
      title: 'Sprache',
      dataMonth: monthOverYears[1][0].totalVariableExpensesCalls,
      color: '#4ca3d8',
      tooltip: monthOverYears[1][0].tooltip,
      phoneNumbers: monthOverYears[1][0].phoneNumbers,
    },
    {
      title: 'Sonstiges',
      dataMonth: monthOverYears[1][0].totalVariableExpensesOther,
      color: '#bfdef1',
      tooltip: monthOverYears[1][0].tooltip,
      phoneNumbers: monthOverYears[1][0].phoneNumbers,
    },
  ]
  const series = [
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'dataMonth',
        categoryX: 'title',
      },
      columns: {
        template: {
          tooltipHTML: tooltipVariable,
          // Events for columns
          events: {
            inited: (event: IEvent) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              event.target.fill = event.target.dataItem._dataContext.color
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              event.target.stroke = event.target.dataItem._dataContext.color
            },
          },
        },
      },
      legendSettings: {
        labelText: `${data[0].phoneNumbers} Rufnummern`,
      },
      tooltip: tooltipStyleObject,
    },
  ]

  return am4core.createFromConfig(
    {
      data,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'title',
          },
          renderer: {
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.42,
            cellEndLocation: 0.58,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      series,
      legend: {
        type: 'Legend',
        contentAlign: 'right',
        markers: {
          width: 0,
          height: 0,
        },
      },
    },
    container,
    am4charts.XYChart,
  )
}

const variableMonthLastYear = (container: string): am4core.Sprite => {
  const monthOverYears = generateMonthData()
  const data = [
    {
      title: 'Daten',
      totalVariableExpensesData: monthOverYears[1][0].totalVariableExpensesData,
      totalVariableExpensesDataLast: monthOverYears[0][0].totalVariableExpensesData,
      tooltip: monthOverYears[0][0].tooltip,
    },
    {
      title: 'Sprache',
      totalVariableExpensesData: monthOverYears[1][0].totalVariableExpensesCalls,
      totalVariableExpensesDataLast: monthOverYears[0][0].totalVariableExpensesCalls,
      tooltip: monthOverYears[0][0].tooltip,
    },
    {
      title: 'Sonstiges',
      totalVariableExpensesData: monthOverYears[1][0].totalVariableExpensesOther,
      totalVariableExpensesDataLast: monthOverYears[0][0].totalVariableExpensesOther,
      tooltip: monthOverYears[0][0].tooltip,
    },
  ]

  const series = [
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'totalVariableExpensesDataLast',
        categoryX: 'title',
      },
      labelText: `${monthOverYears[0][0].year}`,
      name: `${monthOverYears[0][0].year}`,
      stroke: Colors.purpleyPink,
      fill: Colors.purpleyPink,
      columns: {
        tooltipHTML: tooltipVariableQuarterYear,
      },
      tooltip: tooltipStyleObject,
    },
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'totalVariableExpensesData',
        categoryX: 'title',
      },
      labelText: `${monthOverYears[1][0].year}`,
      name: `${monthOverYears[1][0].year}`,
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      columns: {
        tooltipHTML: tooltipVariableQuarterYear,
      },
      tooltip: tooltipStyleObject,
    },
  ]

  return am4core.createFromConfig(
    {
      data,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'title',
          },
          renderer: {
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.4,
            cellEndLocation: 0.6,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      series,
      legend,
    },
    container,
    am4charts.XYChart,
  )
}

const callYearInit = (container: string): am4core.Sprite => {
  const {curYearData} = generateYearData()
  const series = [
    {
      type: 'LineSeries',
      data: curYearData,
      dataFields: {
        dateX: 'date',
        valueY: 'totalNationalCallExpenses',
      },
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      name: 'National',
      tooltipHTML: tooltipCall,
      tooltip: tooltipStyleObject,
      fillOpacity: 1,
      strokeWidth: 1,
      stacked: true,
    },
    {
      type: 'LineSeries',
      dataFields: {
        dateX: 'date',
        valueY: 'totalInternationalCallExpenses',
      },
      stroke: Colors.telefonie,
      fill: Colors.telefonie,
      name: 'International',
      fillOpacity: 1,
      strokeWidth: 1,
      stacked: true,
    },
    {
      type: 'LineSeries',
      dataFields: {
        dateX: 'date',
        valueY: 'totalRoamingCallExpenses',
      },
      stroke: Colors.greyBlue,
      fill: Colors.greyBlue,
      name: 'Roaming',
      fillOpacity: 0.6,
      strokeWidth: 1,
      stacked: true,
    },
  ]

  return am4core.createFromConfig(
    {
      autoSetClassName: true,
      data: curYearData,
      series,
      legend,
      xAxes: [
        {
          type: 'DateAxis',
          dataFields: {
            category: 'date',
          },
          renderer: {
            minGridDistance: 50,
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.3,
            cellEndLocation: 0.8,
          },
          // first item to the right
          startLocation: 0.3,
          endLocation: 0.7,
          baseInterval: {
            timeUnit: 'month',
            count: 1,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          //Disable Y-tooltip
          cursorTooltipEnabled: false,
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      cursor: {
        ...XYcursor,
        maxTooltipDistance: -1,
      },
    },
    container,
    am4charts.XYChart,
  )
}

const callLastYearInit = (container: string): am4core.Sprite => {
  const {prevYearData, curYearData} = generateYearData()
  prevYearData.shift()
  curYearData.shift()
  const series = [
    {
      data: prevYearData,
      type: 'LineSeries',
      legendSettings: {
        labelText: 'Vorjahr',
      },
      dataFields: {
        dateX: 'date',
        valueY: 'totalCall',
      },
      stroke: Colors.purpleyPink,
      fill: Colors.purpleyPink,
      name: `${prevYearData[0].year}`,
      tooltipHTML: tooltipCallLastYear,
      tooltip: tooltipStyleObject,
      strokeWidth: 1,
    },
    {
      data: curYearData,
      type: 'LineSeries',
      legendSettings: {
        labelText: 'Jahresübersicht',
      },
      dataFields: {
        dateX: 'date',
        valueY: 'totalCall',
      },
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      name: `${curYearData[0].year}`,
      tooltipHTML: tooltipCallLastYear,
      tooltip: tooltipStyleObject,
      strokeWidth: 1,
    },
  ]

  return am4core.createFromConfig(
    {
      autoSetClassName: true,
      series,
      legend,
      xAxes: [
        {
          type: 'DateAxis',
          dataFields: {
            category: 'date',
          },
          renderer: {
            minGridDistance: 50,
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.3,
            cellEndLocation: 0.8,
          },
          // first item to the right
          startLocation: 0.3,
          endLocation: 0.7,
          baseInterval: {
            timeUnit: 'month',
            count: 1,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          //Disable Y-tooltip
          cursorTooltipEnabled: false,
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      cursor: {
        ...XYcursor,
        maxTooltipDistance: -1,
      },
    },
    container,
    am4charts.XYChart,
  )
}

const callQuarterInit = (container: string): am4core.Sprite => {
  const {curYearData} = generateQuarterData()
  const series = [
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'totalNationalCallExpenses',
        categoryX: 'monthTitle',
      },
      name: 'National',
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      columns: {
        tooltipHTML: tooltipCall,
      },
      tooltip: tooltipStyleObject,
      stacked: true,
    },
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'totalInternationalCallExpenses',
        categoryX: 'monthTitle',
      },
      name: 'International',
      columns: {
        tooltipHTML: tooltipCall,
      },
      tooltip: tooltipStyleObject,
      stroke: Colors.telefonie,
      fill: Colors.telefonie,
      stacked: true,
    },
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'totalRoamingCallExpenses',
        categoryX: 'monthTitle',
      },
      name: 'Roaming',
      columns: {
        tooltipHTML: tooltipCall,
      },
      tooltip: tooltipStyleObject,
      stroke: Colors.greyBlue,
      fill: Colors.greyBlue,
      stacked: true,
    },
  ]

  return am4core.createFromConfig(
    {
      data: curYearData,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'monthTitle',
          },
          renderer: {
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.4,
            cellEndLocation: 0.6,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      series,
      legend,
    },
    container,
    am4charts.XYChart,
  )
}

const callQuarterLastYearInit = (container: string): am4core.Sprite => {
  const {prevYearData, curYearData} = generateQuarterData()
  const series = [
    {
      data: prevYearData,
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'totalCall',
        categoryX: 'monthTitle',
      },
      labelText: `${prevYearData[0].year}`,
      name: `${prevYearData[0].year}`,
      stroke: Colors.purpleyPink,
      fill: Colors.purpleyPink,
      columns: {
        tooltipHTML: tooltipCallLastYear,
      },
      tooltip: tooltipStyleObject,
    },
    {
      data: curYearData,
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'totalCall',
        categoryX: 'monthTitle',
      },
      labelText: `${curYearData[0].year}`,
      name: `${curYearData[0].year}`,
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      columns: {
        tooltipHTML: tooltipCallLastYear,
      },
      tooltip: tooltipStyleObject,
    },
  ]

  return am4core.createFromConfig(
    {
      data: curYearData,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'monthTitle',
          },
          renderer: {
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.4,
            cellEndLocation: 0.6,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      series,
      legend,
    },
    container,
    am4charts.XYChart,
  )
}

const callMonthInit = (container: string): am4core.Sprite => {
  const monthOverYears = generateMonthData()
  const data = [
    {
      title: 'National',
      dataMonth: monthOverYears[1][0].totalNationalCallExpenses,
      color: '#007cc8',
      tooltip: monthOverYears[1][0].tooltip,
      phoneNumbers: monthOverYears[1][0].phoneNumbers,
    },
    {
      title: 'International',
      dataMonth: monthOverYears[1][0].totalInternationalCallExpenses,
      color: '#4ca3d8',
      tooltip: monthOverYears[1][0].tooltip,
      phoneNumbers: monthOverYears[1][0].phoneNumbers,
    },
    {
      title: 'Roaming',
      dataMonth: monthOverYears[1][0].totalRoamingCallExpenses,
      color: '#bfdef1',
      tooltip: monthOverYears[1][0].tooltip,
      phoneNumbers: monthOverYears[1][0].phoneNumbers,
    },
  ]
  const series = [
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'dataMonth',
        categoryX: 'title',
      },
      columns: {
        template: {
          tooltipHTML: tooltipCall,
          // Events for columns
          events: {
            inited: (event: IEvent) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              event.target.fill = event.target.dataItem._dataContext.color
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              event.target.stroke = event.target.dataItem._dataContext.color
            },
          },
        },
      },
      legendSettings: {
        labelText: `${data[0].phoneNumbers} Rufnummern`,
      },
      tooltip: tooltipStyleObject,
    },
  ]

  return am4core.createFromConfig(
    {
      data,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'title',
          },
          renderer: {
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.42,
            cellEndLocation: 0.58,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      series,
      legend: {
        type: 'Legend',
        contentAlign: 'right',
        markers: {
          width: 0,
          height: 0,
        },
      },
    },
    container,
    am4charts.XYChart,
  )
}

const callMonthLastYearInit = (container: string): am4core.Sprite => {
  const monthOverYears = generateMonthData()
  const data = [
    {
      title: 'National',
      callExpenses: monthOverYears[1][0].totalNationalCallExpenses,
      callExpensesLast: monthOverYears[0][0].totalNationalCallExpenses,
      tooltip: monthOverYears[0][0].tooltip,
    },
    {
      title: 'International',
      callExpenses: monthOverYears[1][0].totalInternationalCallExpenses,
      callExpensesLast: monthOverYears[0][0].totalInternationalCallExpenses,
      tooltip: monthOverYears[0][0].tooltip,
    },
    {
      title: 'Roaming',
      callExpenses: monthOverYears[1][0].totalRoamingCallExpenses,
      callExpensesLast: monthOverYears[0][0].totalRoamingCallExpenses,
      tooltip: monthOverYears[0][0].tooltip,
    },
  ]

  const series = [
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'callExpensesLast',
        categoryX: 'title',
      },
      labelText: `${monthOverYears[0][0].year}`,
      name: `${monthOverYears[0][0].year}`,
      stroke: Colors.purpleyPink,
      fill: Colors.purpleyPink,
      columns: {
        tooltipHTML: tooltipCallLastYear,
      },
      tooltip: tooltipStyleObject,
    },
    {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'callExpenses',
        categoryX: 'title',
      },
      labelText: `${monthOverYears[1][0].year}`,
      name: `${monthOverYears[1][0].year}`,
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      columns: {
        tooltipHTML: tooltipCallLastYear,
      },
      tooltip: tooltipStyleObject,
    },
  ]

  return am4core.createFromConfig(
    {
      data,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'title',
          },
          renderer: {
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.4,
            cellEndLocation: 0.6,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      series,
      legend,
    },
    container,
    am4charts.XYChart,
  )
}

const generateYearData = (): IGeneratedData => {
  const prevYearData: Array<IW1Resp> = []
  const curYearData: Array<IW1Resp> = []
  for (let i = 0; i < WidgetData.prevYear.length; i++) {
    prevYearData[i] = generateItemData(WidgetData.prevYear[i])
    // we need this line for comparing data with same date
    prevYearData[i].date = new Date(WidgetData.curYear[i].year, Number(WidgetData.curYear[i].month))
    prevYearData[i].tooltip = generateTooltip(WidgetData.prevYear, WidgetData.curYear, i)
    if (WidgetData.curYear[i]) {
      curYearData[i] = generateItemData(WidgetData.curYear[i])
      curYearData[i].tooltip = generateTooltip(WidgetData.prevYear, WidgetData.curYear, i)
    }
  }

  return {prevYearData, curYearData}
}

const generateQuarterData = (): IGeneratedData => {
  const prevYearData: Array<IW1Resp> = []
  const curYearData: Array<IW1Resp> = []

  for (let step = 1; step <= 3; step++) {
    prevYearData.unshift(WidgetData.prevYear[WidgetData.prevYear.length - step])
    curYearData.unshift(WidgetData.curYear[WidgetData.curYear.length - step])
  }

  for (let i = 0; i < prevYearData.length; i++) {
    prevYearData[i] = generateItemData(prevYearData[i])
    prevYearData[i].tooltip = generateTooltip(prevYearData, curYearData, i)

    if (curYearData[i]) {
      curYearData[i] = generateItemData(curYearData[i])
      curYearData[i].tooltip = generateTooltip(prevYearData, curYearData, i)
    }
  }
  return {prevYearData, curYearData}
}

const generateMonthData = (): Array<Array<IW1Resp>> => {
  const monthDataCurr: Array<IW1Resp> = [WidgetData.curYear[WidgetData.curYear.length - 1]]
  const monthDataPrev: Array<IW1Resp> = [WidgetData.prevYear[WidgetData.prevYear.length - 1]]

  monthDataPrev.forEach((item: IW1Resp) => {
    item = generateItemData(item)
    item.tooltip = generateTooltip([item], monthDataCurr, 0)
  })
  monthDataCurr.forEach((item: IW1Resp) => {
    item = generateItemData(item)
    item.tooltip = generateTooltip(monthDataPrev, [item], 0)
  })
  return [monthDataPrev, monthDataCurr]
}

const generateItemData = (item: IW1Resp): IW1Resp => {
  item.date = new Date(item.year, Number(item.month))
  item.fullExpenses = getTotalAmount(item)
  item.fullExpensesPerUser = getTotalAmount(item) / item.phoneNumbers
  item.totalVariable = getTotalVariable(item)
  item.totalCall = getTotalCall(item)
  item.monthTitle = Month[item.month]
  return item
}

const generateTooltip = (prevYearData: Array<IW1Resp>, curYearData: Array<IW1Resp>, i: number): ITooltip => ({
  prevYear: String(prevYearData[i].year),
  curYear: String(curYearData[i].year),
  prevPhoneNumbers: `${prevYearData[i].phoneNumbers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0} St.`,
  curPhoneNumbers: `${
    (curYearData[i] && curYearData[i].phoneNumbers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) || 0
  } St.`,

  title: '',
  monthTitle: Month[curYearData[i].month],
  monthLast: Month[prevYearData[i].month],
  basicFee: `${replaceSeparator(curYearData[i].totalBasicFee || 0)} €`,
  basicFeeLast: `${replaceSeparator(prevYearData[i].totalBasicFee || 0)} €`,
  totalVariableExpensesData: `${replaceSeparator(curYearData[i].totalVariableExpensesData || 0)} €`,
  totalVariableExpensesDataLast: `${replaceSeparator(prevYearData[i].totalVariableExpensesData || 0)} €`,
  totalVariableExpensesCalls: `${replaceSeparator(curYearData[i].totalVariableExpensesCalls || 0)} €`,
  totalVariableExpensesCallsLast: `${replaceSeparator(prevYearData[i].totalVariableExpensesCalls || 0)} €`,
  totalVariableExpensesOther: `${replaceSeparator(curYearData[i].totalVariableExpensesOther || 0)} €`,
  totalVariableExpensesOtherLast: `${replaceSeparator(prevYearData[i].totalVariableExpensesOther || 0)} €`,

  totalNationalCallExpenses: `${replaceSeparator(curYearData[i].totalNationalCallExpenses || 0)} €`,
  totalNationalCallExpensesLast: `${replaceSeparator(prevYearData[i].totalNationalCallExpenses || 0)} €`,
  totalInternationalCallExpenses: `${replaceSeparator(curYearData[i].totalInternationalCallExpenses || 0)} €`,
  totalInternationalCallExpensesLast: `${replaceSeparator(prevYearData[i].totalInternationalCallExpenses || 0)} €`,
  totalRoamingCallExpenses: `${replaceSeparator(curYearData[i].totalRoamingCallExpenses || 0)} €`,
  totalRoamingCallExpensesLast: `${replaceSeparator(prevYearData[i].totalRoamingCallExpenses || 0)} €`,

  totalOtherExpenses: `${replaceSeparator(curYearData[i].totalOtherExpenses || 0)} €`,
  totalOtherExpensesLast: `${replaceSeparator(prevYearData[i].totalOtherExpenses || 0)} €`,
  numbersCount: `${curYearData[i].phoneNumbers || 0} St.`,
  numbersCountLast: `${prevYearData[i].phoneNumbers || 0} St.`,
  totalVariableLast: `${replaceSeparator(getTotalVariable(prevYearData[i]))} €`,
  totalVariable: `${replaceSeparator(getTotalVariable(curYearData[i]))} €`,

  totalCallLast: `${replaceSeparator(getTotalCall(prevYearData[i]))} €`,
  totalCall: `${replaceSeparator(getTotalCall(curYearData[i]))} €`,

  callPerUser: `${replaceSeparator(getTotalCall(curYearData[i]) / curYearData[i].phoneNumbers || 0)} €`,
  callPerUserLast: `${replaceSeparator(getTotalCall(prevYearData[i]) / prevYearData[i].phoneNumbers || 0)} €`,

  fullExpensesPerUserLast: `${replaceSeparator(
    (prevYearData[i].totalBasicFee + prevYearData[i].totalOtherExpenses + getTotalVariable(prevYearData[i])) /
      prevYearData[i].phoneNumbers || 0,
  )} €`,
  fullExpensesPerUser: `${replaceSeparator(
    (curYearData[i].totalBasicFee + curYearData[i].totalOtherExpenses + getTotalVariable(curYearData[i])) /
      curYearData[i].phoneNumbers || 0,
  )} €`,
  fullExpenses: `${replaceSeparator(
    curYearData[i].totalAmountNet || (curYearData[i].totalBasicFee + curYearData[i].totalOtherExpenses + getTotalVariable(curYearData[i])),
  )} €`,
  fullExpensesLast: `${replaceSeparator(
    prevYearData[i].totalAmountNet || (prevYearData[i].totalBasicFee + prevYearData[i].totalOtherExpenses + getTotalVariable(prevYearData[i])),
  )} €`,
})

const getTotalVariable = (data: IW1Resp) => {
  return data.totalVariableExpensesData + data.totalVariableExpensesCalls + data.totalVariableExpensesOther || 0
}

const getTotalCall = (data: IW1Resp) => {
  return data.totalNationalCallExpenses + data.totalInternationalCallExpenses + data.totalRoamingCallExpenses || 0
}

const getTotalAmount = (data: IW1Resp) => {
  let totalAmountNet = data.totalAmountNet
  if (totalAmountNet == undefined || totalAmountNet == 0) {
    totalAmountNet = data.totalBasicFee + data.totalOtherExpenses + getTotalVariable(data)
  }

  return totalAmountNet
}
