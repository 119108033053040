import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import {groupBy} from 'lodash'
import {ForceDirectedTree} from '@amcharts/amcharts4/plugins/forceDirected'
import {AxiosResponse} from 'axios'
import {
  Colors,
  legend,
  legendHide,
  Month,
  replaceSeparator,
  tooltipStyleObject,
} from '../../am4charts'
import {StateInterface} from '../../UserContext'
import API from '../../API'
import {tooltipCurCosts, tooltipCurData, tooltipPrev} from './W2Tooltips'

interface IW2CostResp {
  year: number
  month: number
  groupings: Array<IGroupings>
}
interface IW2DataResp {
  year: number
  month: number
  groupings: Array<IGroupings>
}
interface IW2NullUsersResp {
  year: number | string
  month: number
  amount: number
  fontSize?: number
  fixed?: boolean
  x?: am4core.Percent
  y?: am4core.Percent
}
interface IGroupings {
  users: number
  total: number
  avgPerUser: number
  type?: string
  from?: number
  to?: number
  category?: string
  tooltip?: ITooltip
}
interface ITooltip {
  [propName: string]: number | string
}
interface IWidgetData {
  cost: Array<IW2CostResp>
  data: Array<IW2DataResp>
  nullUsers: Array<IW2NullUsersResp>
}

interface IDisplayDate {
  month: number
  prevYear: number
  year: number
}

type SetDisplayDate = (displayDate: IDisplayDate) => void

const yAxes = [
  {
    type: 'ValueAxis',
    min: 0,
    numberFormatter: {
      numberFormat: '#,###.##  St.',
    },
    renderer: {
      opposite: true,
      labels: {
        fill: Colors.brownGrey,
        align: 'right',
      },
    },
  },
]
let WidgetData: IWidgetData = {
  cost: [],
  data: [],
  nullUsers: [],
}

interface IServerResponse {
  data: IW2CostResp | IW2DataResp | IW2NullUsersResp
}

const fetchWidgetData = async (
  context: StateInterface,
  checked: boolean,
  type: string,
  setDisplayDate: SetDisplayDate,
): Promise<IW2CostResp | IW2DataResp | IW2NullUsersResp> => {
  const url = `/providers/${context.provider}/w2/${type}?previousYear=${checked}${context.filter}`
  // eslint-disable-next-line no-return-await
  return await API.get(url).then((resp: AxiosResponse<IServerResponse>) => {
    const data = resp.data.data as IW2DataResp

    const { month, year, prevYear } = {
      month: data.month,
      year: checked ? data.year + 1 : data.year,
      prevYear: checked ? data.year : data.year - 1,
    } as IDisplayDate

    setDisplayDate({ month, year, prevYear })
    return data
  })
}

const getCostData = async (
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
  changeContext: boolean,
  setDisplayDate: SetDisplayDate,
): Promise<Array<IW2CostResp>> => {
  checkChangeContext(changeContext)
  if (!WidgetData.cost.length) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      WidgetData.cost = await Promise.all([
        fetchWidgetData(context, false, 'cost', setDisplayDate),
        fetchWidgetData(context, true, 'cost', setDisplayDate),
      ])
    } catch {
      if (!context.error) {
        setContext({error: true})
      }
    }
  }
  return WidgetData.cost
}

const getDataData = async (
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
  changeContext: boolean,
  setDisplayDate: SetDisplayDate,
): Promise<Array<IW2DataResp>> => {
  checkChangeContext(changeContext)
  if (!WidgetData.data.length) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      WidgetData.data = await Promise.all([
        fetchWidgetData(context, false, 'data', setDisplayDate),
        fetchWidgetData(context, true, 'data', setDisplayDate),
      ])
    } catch {
      if (!context.error) {
        setContext({error: true})
      }
    }
  }
  return WidgetData.data
}

const getNullUsersData = async (
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
  changeContext: boolean,
  setDisplayDate: SetDisplayDate,
): Promise<Array<IW2NullUsersResp>> => {
  checkChangeContext(changeContext)
  if (!WidgetData.nullUsers.length) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      WidgetData.nullUsers = await Promise.all([
        fetchWidgetData(context, false, 'null-users', setDisplayDate),
        fetchWidgetData(context, true, 'null-users', setDisplayDate),
      ])
    } catch {
      if (!context.error) {
        setContext({error: true})
      }
    }
  }
  return WidgetData.nullUsers
}

const checkChangeContext = (changeContext: boolean): void => {
  if (changeContext) {
    WidgetData = {
      cost: [],
      data: [],
      nullUsers: [],
    }
  }
}

export const W2ChartInit = async (
  chartId: string,
  tab: number,
  checked: boolean,
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
  changeContext: boolean,
  displayDate: IDisplayDate,
  setDisplayDate: SetDisplayDate,
): Promise<am4core.Sprite> => {
  switch (tab) {
    case 0: {
      const data: Array<IW2CostResp> = await getCostData(context, setContext, changeContext, setDisplayDate)
      return CostInit(chartId, checked, data, displayDate)
    }
    case 1: {
      const data: Array<IW2DataResp> = await getDataData(context, setContext, changeContext, setDisplayDate)
      return DataInit(chartId, checked, data, displayDate)
    }
    case 2: {
      const data: Array<IW2NullUsersResp> = await getNullUsersData(context, setContext, changeContext, setDisplayDate)
      return NullUsersInit(chartId, checked, data)
    }
    default: {
      const data: Array<IW2CostResp> = await getCostData(context, setContext, changeContext, setDisplayDate)
      return CostInit(chartId, checked, data, displayDate)
    }
  }
}

const CostInit = (container: string, checked: boolean, data: Array<IW2CostResp>, displayDate: IDisplayDate): am4core.Sprite => {
  const {prevYearData, curYearData} = generateCostData(data, displayDate)
  const series = [
    {
      type: 'ColumnSeries',
      data: prevYearData,
      legendSettings: {
        labelText: String(displayDate.prevYear),
      },
      dataFields: {
        valueY: 'users',
        categoryX: 'category',
      },
      stroke: Colors.purpleyPink,
      fill: Colors.purpleyPink,
      columns: {
        width: checked ? 13 : 28,
        tooltipHTML: tooltipPrev('Verbrauchskosten'),
      },
      tooltip: {
        ...tooltipStyleObject,
        pointerOrientation: 'horizontal',
      },
    },
    {
      type: 'ColumnSeries',
      data: curYearData,
      legendSettings: {
        labelText: displayDate.year,
      },
      dataFields: {
        valueY: 'users',
        categoryX: 'category',
      },
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      columns: {
        width: checked ? 13 : 28,
        tooltipHTML: checked ? tooltipPrev('Verbrauchskosten') : tooltipCurCosts,
      },
      tooltip: {
        ...tooltipStyleObject,
        pointerOrientation: 'horizontal',
      },
    },
  ]

  return am4core.createFromConfig(
    {
      data: checked ? prevYearData : curYearData,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'category',
          },
          renderer: {
            minGridDistance: 1,
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.3,
            cellEndLocation: 0.7,
            paddingBottom: 0,
          },
        },
      ],
      yAxes,
      series: checked ? [...series] : [series[1]],
      legend: checked ? legend : legendHide,
    },
    container,
    am4charts.XYChart,
  )
}

const DataInit = (container: string, checked: boolean, data: Array<IW2DataResp>, displayDate: IDisplayDate): am4core.Sprite => {
  const {prevYearData, curYearData, internationalData, nationalData} = generateDataData(data, checked, displayDate)
  const series = [
    {
      type: 'ColumnSeries',
      data: checked ? prevYearData : internationalData,
      legendSettings: {
        labelText: checked ? String(displayDate.prevYear) : 'international',
      },
      dataFields: {
        valueY: 'users',
        categoryX: 'category',
      },
      stroke: checked ? Colors.purpleyPink : Colors.cerulean,
      fill: checked ? Colors.purpleyPink : Colors.cerulean,
      columns: {
        width: checked ? 13 : 28,
        tooltipHTML: checked ? tooltipPrev('Datenverbrauch') : tooltipCurData,
      },
      tooltip: {
        ...tooltipStyleObject,
        pointerOrientation: 'horizontal',
      },
    },
    {
      type: 'ColumnSeries',
      data: checked ? curYearData : nationalData,
      legendSettings: {
        labelText: checked ? String(displayDate.year) : 'national',
      },
      dataFields: {
        valueY: 'users',
        categoryX: 'category',
      },
      stroke: checked ? Colors.cerulean : Colors.greyBlue,
      fill: checked ? Colors.cerulean : Colors.greyBlue,
      columns: {
        width: checked ? 13 : 28,
        tooltipHTML: checked ? tooltipPrev('Datenverbrauch') : tooltipCurData,
      },
      tooltip: {
        ...tooltipStyleObject,
        pointerOrientation: 'horizontal',
      },
    },
  ]

  return am4core.createFromConfig(
    {
      data: checked ? prevYearData : internationalData,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'category',
          },
          renderer: {
            minGridDistance: 1,
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.3,
            cellEndLocation: 0.7,
            paddingBottom: 0,
          },
        },
      ],
      yAxes,
      series,
      legend,
    },
    container,
    am4charts.XYChart,
  )
}

const NullUsersInit = (container: string, checked: boolean, data: Array<IW2NullUsersResp>): am4core.Sprite => {
  const nullUsersData = generateNullUsersData(data, checked)
  const series = [
    {
      type: 'ForceDirectedSeries',
      dataFields: {
        value: 'amount',
        name: 'year',
        fixed: 'fixed',
      },
      nodes: {
        propertyFields: {
          x: 'x',
          y: 'y',
        },
        label: {
          adapter: [
            {
              key: 'html',
              callback: (label: any, target: any) => {
                const maxScale = 1.32
                let scale = 0.66 + 0.33 * (target.dataItem?._dataContext.fontSize ?? 0)
                if (scale > maxScale) {
                  scale = maxScale
                }
                return `<div class="no-consumption-label" style="color: {color};transform: scale(${scale})">
                    <div class="amount">{amount}</div>
                    <div class="numbers">Rufnummern</div>
                </div>`
              },
            },
          ],
        },
        circle: {
          fill: Colors.white,
          strokeWidth: 6.4,
        },
      },
      colors: {
        list: checked ? [Colors.cerulean, '', Colors.purpleyPink] : [Colors.cerulean],
      },
      minRadius: checked ? 50 : 73,
      maxRadius: 73,
      centerStrength: 0.4,
      tooltip: {
        ...tooltipStyleObject,
        pointerOrientation: 'horizontal',
      },
    },
  ]

  return am4core.createFromConfig(
    {
      data: checked ? nullUsersData : [nullUsersData[0]],
      series,
    },
    container,
    ForceDirectedTree,
  )
}

const generateCostData = (data: Array<IW2CostResp>, displayDate: IDisplayDate) => {
  const prevYear: IW2CostResp = data[1]
  const curYear: IW2CostResp = data[0]
  const prevYearData: Array<IGroupings> = prevYear ? prevYear.groupings : []
  const curYearData: Array<IGroupings> = curYear ? curYear.groupings : []
  const maxLengthData = Math.max(prevYearData.length, curYearData.length)

  for (let i = 0; i < maxLengthData; i++) {
    if (prevYearData && prevYearData[i]) {
      prevYearData[i].category = generateCategory(prevYearData[i], '€')
      prevYearData[i].tooltip = generateCostTooltip(prevYearData, curYearData, i, '€', displayDate)
    }
    if (curYearData && curYearData[i]) {
      curYearData[i].category = generateCategory(curYearData[i], '€')
      curYearData[i].tooltip = generateCostTooltip(prevYearData, curYearData, i, '€', displayDate)
    }
  }

  return {prevYearData, curYearData}
}

const generateDataData = (data: Array<IW2DataResp>, checked: boolean, displayDate: IDisplayDate) => {
  const prevYear: IW2DataResp = data[1]
  const curYear: IW2DataResp = data[0]

  const convertYearData = (groupings: Array<IGroupings>) => {
    const groupByFrom = groupBy(groupings, 'from')
    const yearData = []

    for (const key in groupByFrom) {
      const first = {...groupByFrom[key][0]}
      const second = groupByFrom[key][1]
      first.users = first.users + second.users
      first.total = Number(first.total) + Number(second.total)
      first.avgPerUser = Number(first.avgPerUser) + Number(second.avgPerUser)
      yearData.push(first)
    }

    return yearData
  }

  const prevYearData = convertYearData(prevYear ? prevYear.groupings : [])
  const curYearData = convertYearData(curYear.groupings)
  const internationalData = curYear.groupings.filter(({type}: IGroupings) => type === 'international')
  const nationalData = curYear.groupings.filter(({type}: IGroupings) => type === 'national')
  const maxLengthData = Math.max(prevYearData.length, curYearData.length)

  for (let i = 0; i < maxLengthData; i++) {
    if (internationalData && internationalData[i]) {
      internationalData[i].category = generateCategory(internationalData[i], 'GiB')
    }
    if (nationalData && nationalData[i]) {
      nationalData[i].category = generateCategory(nationalData[i], 'GiB')
    }
    if (prevYearData[i]) {
      prevYearData[i].category = generateCategory(prevYearData[i], 'GiB')
    }
    if (curYearData[i]) {
      curYearData[i].category = generateCategory(curYearData[i], 'GiB')
    }

    if (checked) {
      if (prevYearData && prevYearData[i]) {
        prevYearData[i].tooltip = generateCostTooltip(prevYearData, curYearData, i, 'GiB', displayDate)
      }
      if (curYearData && curYearData[i]) {
        curYearData[i].tooltip = generateCostTooltip(prevYearData, curYearData, i, 'GiB', displayDate)
      }
    } else {
      if (nationalData && nationalData[i]) {
        nationalData[i].tooltip = generateCurDataTooltip(internationalData, nationalData, i, displayDate)
      }
      if (internationalData && internationalData[i]) {
        internationalData[i].tooltip = generateCurDataTooltip(internationalData, nationalData, i, displayDate)
      }
    }
  }

  return {prevYearData, curYearData, internationalData, nationalData}
}

const generateNullUsersData = (data: Array<IW2NullUsersResp>, checked: boolean) =>
  data.map((item, index) => {
    if (index === 0) {
      item.fontSize = item.amount && data[0].amount ? item.amount / data[0].amount : 1
      if (checked) {
        item.x = am4core.percent(65)
        item.y = am4core.percent(30)
      } else {
        delete item.x
        delete item.y
      }
    } else if (index === 1) {
      item.fontSize = item.amount && data[1].amount ? item.amount / data[1].amount : 1
      if (checked) {
        item.x = am4core.percent(35)
        item.y = am4core.percent(55)
      }
    }
    item.fixed = true
    item.year = String(item.year)
    return item
  })

const generateCostTooltip = (
  prevYearData: Array<IGroupings> = [],
  curYearData: Array<IGroupings>,
  i: number,
  value: string,
  displayDate: IDisplayDate,
): ITooltip => ({
  month: Month[displayDate.month],
  prevYear: String(displayDate.prevYear),
  curYear: String(displayDate.year),
  prevTotal: prevYearData && prevYearData[i] ? `${replaceSeparator(prevYearData[i].total)} ${value}` : '',
  curTotal: curYearData && curYearData[i] ? `${replaceSeparator(curYearData[i].total)} ${value}` : '',
  prevUsers: prevYearData && prevYearData[i] ? prevYearData[i].users : '',
  curUsers: curYearData && curYearData[i] ? curYearData[i].users : '',
  prevAvgPerUser: prevYearData && prevYearData[i] ? `${replaceSeparator(prevYearData[i].avgPerUser)} ${value}` : '',
  curAvgPerUser: curYearData && curYearData[i] ? `${replaceSeparator(curYearData[i].avgPerUser)} ${value}` : '',
})

const generateCurDataTooltip = (
  internationalData: Array<IGroupings>,
  nationalData: Array<IGroupings>,
  i: number,
  displayDate: IDisplayDate,
): ITooltip => ({
  month: Month[displayDate.month],
  year: String(displayDate.year),
  intTotal: internationalData[i] && `${replaceSeparator(internationalData[i].total)} GiB`,
  natTotal: nationalData[i] && `${replaceSeparator(nationalData[i].total)} GiB`,
  intUsers: internationalData[i] && internationalData[i].users,
  natUsers: nationalData[i] && nationalData[i].users,
  intAvgPerUser: internationalData[i] && `${replaceSeparator(internationalData[i].avgPerUser)} GiB`,
  natAvgPerUser: nationalData[i] && `${replaceSeparator(nationalData[i].avgPerUser)} GiB`,
})

const generateCategory = (groupings: IGroupings, value: string) => {
  if (!groupings.from && !groupings.to) {
    return '0'
  } else if (!groupings.from) {
    return `< ${groupings.to} ${value}`
  }
  return groupings.to ? `${groupings.from || 0} - ${groupings.to} ${value}` : `> ${groupings.from} ${value}`
}
