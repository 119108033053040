import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import {AxiosResponse} from 'axios'
import {
  Colors,
  getCurMonth,
  getCurYear,
  getPrevYear,
  getQuarterLabel,
  legend,
  legendHide,
  Month,
  replaceSeparator,
  tooltipStyleObject,
} from '../../am4charts'
import {StateInterface} from '../../UserContext'
import API from '../../API'
import {tooltipCurYear, tooltipPrevYear} from './W4Tooltips'

interface IW4Resp {
  year: number
  month: number
  yearly: IContent
  quarterly: IContent
  monthly: IContent
}

interface IContent {
  phoneNumbers: number
  providerFees: number
  thirdPartyFees: number
  tariffOptions: number
}

interface ITooltip {
  [propName: string]: number | string
}

interface IDateLabels {
  currYear: string
  prevYear: string
  month: string
  quarter: string
}

interface IWidgetData {
  currYear: Array<IW4Resp>
  prevYear: Array<IW4Resp>
  dateLabels: IDateLabels
}

interface IServerResponse {
  data: Array<IW4Resp>
}

enum Periods {
  yearly,
  quarterly,
  monthly,
}

const placeholderDateLabels: IDateLabels = {
  currYear: String(getCurYear()),
  prevYear: String(getPrevYear()),
  month: String(Month[getCurMonth()]),
  quarter: getQuarterLabel(getCurMonth(), getCurYear())
}

let WidgetData = {
  currYear: [],
  prevYear: [],
  dateLabels: { ...placeholderDateLabels },
}

const checkChangeContext = (changeContext: boolean) => {
  if (changeContext) {
    WidgetData = {
      currYear: [],
      prevYear: [],
      dateLabels: { ...placeholderDateLabels },
    }
  }
}

const fetchWidgetData = async (context: StateInterface, checked: boolean): Promise<Array<IW4Resp>> => {
  const url = `/providers/${context.provider}/w4?previousYear=${checked}${context.filter}`
  // eslint-disable-next-line no-return-await
  return await API.get(url).then((resp: AxiosResponse<IServerResponse>) => resp.data.data)
}

const getData = async (
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
  changeContext: boolean,
) => {
  checkChangeContext(changeContext)
  if (changeContext) {
    try {
      const data: any = await Promise.all([fetchWidgetData(context, false), fetchWidgetData(context, true)])
      WidgetData.currYear = data[0]
      WidgetData.prevYear = data[1]
      WidgetData.dateLabels.currYear = String(data[0].year)
      WidgetData.dateLabels.prevYear = String(data[1].year)
      WidgetData.dateLabels.month = Month[data[0].month]
      WidgetData.dateLabels.quarter = getQuarterLabel(data[0].month, data[0].year)
    } catch {
      if (!context.error) {
        setContext({error: true})
      }
    }
  }
  return WidgetData
}

export const W4ChartInit = async (
  chartId: string,
  checked: boolean,
  range: number,
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
  changeContext: boolean,
): Promise<am4core.Sprite> => {
  const data = await getData(context, setContext, changeContext)
  return YearInit(chartId, checked, data, Periods[range])
}

const YearInit = (container: string, checked: boolean, data: IWidgetData, period: string): am4core.Sprite => {
  const {prevYearData, curYearData} = generateYearData(data, period)
  const series = [
    {
      type: 'ColumnSeries',
      data: prevYearData,
      legendSettings: {
        labelText: period === Periods[0] ? 'Vorjahr' : data.dateLabels.prevYear,
      },
      dataFields: {
        valueY: 'value',
        categoryX: 'category',
      },
      stroke: Colors.purpleyPink,
      fill: Colors.purpleyPink,
      columns: {
        tooltipHTML: tooltipPrevYear,
      },
      tooltip: tooltipStyleObject,
    },
    {
      type: 'ColumnSeries',
      data: curYearData,
      legendSettings: {
        labelText: period === Periods[0] ? 'Jahresübersicht' : data.dateLabels.currYear,
      },
      dataFields: {
        valueY: 'value',
        categoryX: 'category',
      },
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      columns: {
        tooltipHTML: checked ? tooltipPrevYear : tooltipCurYear,
      },
      tooltip: tooltipStyleObject,
    },
  ]

  return am4core.createFromConfig(
    {
      data: curYearData,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'category',
          },
          renderer: {
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
              location: 0.525,
              wrap: true,
              maxWidth: 105,
              textAlign: 'middle',
            },
            cellStartLocation: 0.4,
            cellEndLocation: 0.6,
            paddingBottom: 0,
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.## €',
          },
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      series: checked ? [...series] : [series[1]],
      legend: checked ? legend : legendHide,
    },
    container,
    am4charts.XYChart,
  )
}

const generateYearData = (data: IWidgetData, period: string) => {
  const prevYearData: Array<IW4Resp> = data.prevYear
  const curYearData: Array<IW4Resp> = data.currYear
  // @ts-ignore
  if (prevYearData[period] && curYearData[period]) {
    // @ts-ignore
    const tooltip: ITooltip = generateTooltip(prevYearData[period], curYearData[period], period, data.dateLabels)
    return {
      prevYearData: [
        {
          category: 'Provider- leistungen',
          // @ts-ignore
          value: prevYearData[period].providerFees,
          tooltip,
        },
        {
          category: 'Drittanbieter- leistungen',
          // @ts-ignore
          value: prevYearData[period].thirdPartyFees,
          tooltip,
        },
        {
          category: 'Tarif- optionen',
          // @ts-ignore
          value: prevYearData[period].tariffOptions,
          tooltip,
        },
      ],
      curYearData: [
        {
          category: 'Provider- leistungen',
          // @ts-ignore
          value: curYearData[period].providerFees,
          tooltip,
        },
        {
          category: 'Drittanbieter- leistungen',
          // @ts-ignore
          value: curYearData[period].thirdPartyFees,
          tooltip,
        },
        {
          category: 'Tarif- optionen',
          // @ts-ignore
          value: curYearData[period].tariffOptions,
          tooltip,
        },
      ],
    }
  }
  return {
    prevYearData: [],
    curYearData: [],
  }
}

const generateTooltip = (
  prevYearPeriod: IContent,
  curYearPeriod: IContent,
  period: string,
  dateLabels: IDateLabels
): ITooltip => {
  const prevTotal = prevYearPeriod.providerFees + prevYearPeriod.thirdPartyFees + prevYearPeriod.tariffOptions
  const curTotal = curYearPeriod.providerFees + curYearPeriod.thirdPartyFees + curYearPeriod.tariffOptions
  return {
    month: period === 'monthly' ? dateLabels.month : '',
    prevYear: period === 'quarterly' ? '' : dateLabels.prevYear,
    curYear: period === 'quarterly' ? '' : dateLabels.currYear,
    period: period === 'quarterly' ? dateLabels.quarter : '',
    prevPhoneNumbers: `${prevYearPeriod.phoneNumbers} St.`,
    curPhoneNumbers: `${curYearPeriod.phoneNumbers} St.`,
    prevProviderFees: `${replaceSeparator(prevYearPeriod.providerFees || 0)} €`,
    curProviderFees: `${replaceSeparator(curYearPeriod.providerFees || 0)} €`,
    prevThirdPartyFees: `${replaceSeparator(prevYearPeriod.thirdPartyFees || 0)} €`,
    curThirdPartyFees: `${replaceSeparator(curYearPeriod.thirdPartyFees || 0)} €`,
    // place for new property
    curTarrif: `${replaceSeparator(curYearPeriod.tariffOptions || 0)} €`,
    prevTarrif: `${replaceSeparator(prevYearPeriod.tariffOptions || 0)} €`,
    prevTotal: `${replaceSeparator(prevTotal)} €`,
    curTotal: `${replaceSeparator(curTotal)} €`,
    prevAverage: `${replaceSeparator(prevYearPeriod.phoneNumbers ? prevTotal / prevYearPeriod.phoneNumbers : 0)} €`,
    curAverage: `${replaceSeparator(curYearPeriod.phoneNumbers ? curTotal / curYearPeriod.phoneNumbers : 0)} €`,
  }
}
