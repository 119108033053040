import React, {FC, useContext, useEffect, useState} from 'react'
import {UserContext} from '../../UserContext'
import WidgetSwitcher from '../Widget/WidgetSwitcher'
import W1 from '../W1/W1'
import W2 from '../W2/W2'
import W3 from '../W3/W3'
import W4 from '../W4/W4'
import W5 from '../W5/W5'
import W6 from '../W6/W6'
import W7 from '../W7/W7'
import W8 from '../W8/W8'
import W9 from '../W9/W9'
import W10 from '../W10/W10'
import API from '../../API'
import W11 from '../W11/W11';
//import W12 from '../W12/W12';

const Widgets: FC = () => {
  const context = useContext(UserContext)[0]
  const [tab, setTab] = useState(0)
  const [hasPoolingData, setHasPoolingData] = useState(false)

  useEffect(() => {
    async function checkHasPoolingData() {
      if (context.provider) {
        setHasPoolingData(
          (await API.get(`/providers/${context.provider}/has-pooling-data?${context.filter}`))['data'][
            'hasPoolingData'
          ],
        )
      }
    }
    void checkHasPoolingData()
  }, [context])

  const onSwitchTab = (index: number) => {
    setTab(index)
  }

  // Skip third tab if pooling data is hidden
  let actualTab = tab
  // currently disabled -> Pooling requires futher testing
  //let switchersTitle = ['Verbrauch', 'Zusatzkosten', 'Pooling-Verträge', 'Verträge und Geräte', 'Alle']
  //if (!hasPoolingData) {
 
  const switchersTitle = ['Verbrauch', 'Zusatzkosten', 'Verträge und Geräte', 'Alle']
    if (tab >= 2) {
      actualTab += 1
    }
  //}

  return (
    <>
      <WidgetSwitcher activeTab={tab} onSwitchTab={onSwitchTab} switchersTitle={switchersTitle} className={'main'} />
      <div className="container pt-40">
        {(() => {
          switch (actualTab) {
            case 0:
              return (
                <div className="row">
                  <div className="col-2">
                    <W1 />
                  </div>
                  <div className="col-1">
                    <W2 />
                  </div>
                </div>
              )
            case 1:
              return (
                <div className="row">
                  <div className="col-1">
                    <W4 />
                  </div>
                  <div className="col-1">
                    <W5 />
                  </div>
                </div>
              )
            case 2:
              return (
                <div className="row">
                  <div className="col-2">
                    <W6 />
                  </div>
                  <div className="col-1">
                    <W7 />
                  </div>
                </div>
              )
            case 3:
              return (
                <div>
                  <div className="row">
                    <div className="col-3">
                      <W3 />
                    </div>
                  </div>
                  {/* currently disabled -> Bug Ticket created
                  <div className="row">
                    <div className="col-3">
                      <W8 />
                    </div>
                  </div>*/}
                </div>
              )
            case 4:
              return (
                <>
                  <div className="row">
                    <div className="col-2">
                      <W1 />
                    </div>
                    <div className="col-1">
                      <W2 />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <W3 />
                    </div>
                    <div className="col-1">
                      <W4 />
                    </div>
                    <div className="col-1">
                      <W5 />
                    </div>
                  </div>
                  {/* currently disabled -> Pooling requires futher testing
                  <div className="row">
                    <div className="col-2">
                      <W6 />
                    </div>
                    <div className="col-1">
                      <W7 />
                    </div> 
                  </div> 
                  <div className="row">
                    <div className="col-3">
                      <W8 />
                    </div>
                  </div>*/}
                  <div className="row">
                    <div className="col-1">
                      <W11/>
                    </div>
                   {/* needs to be refactored
                   <div className="col-1">
                      <W12/>
                    </div> */}
                  </div>
                </>
              )
            default:
              return
          }
        })()}
      </div>
    </>
  )
}

export default Widgets
